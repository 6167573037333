import React, { useState, useEffect, useRef } from 'react'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Slider from '@material-ui/core/Slider'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'

import FormLabel from '@material-ui/core/FormLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { deletePartner, updatePartner, updateSniUsers } from '../services/database'

import { sortedArrayFromObject } from '../utils'
import { Collapse } from '@material-ui/core'

const PartnerDetail = (props) => {

  const [partnerId, setPartnerId] = useState('')
  const [partner, setPartner] = useState(null)
  const [name, setName] = useState('')
  const [intro, setIntro] = useState('')
  const [localeOptions, setLocaleOptions] = useState([])
  const [locales, setLocales] = useState([])
  const [groups, setGroups] = useState([])
  const [documentGroups, setDocumentGroups] = useState([])
  const [signupRule, setSignupRule] = useState('open')
  const [usersPath, setUsersPath] = useState('')
  const [logoMode, setLogoMode] = useState('universal')
  const [logo, setLogo] = useState({
    data: null,
    format: ''
  })
  const [logos, setLogos] = useState({})
  const [editingLogo, setEditingLogo] = useState('')
  const imageInputRef = useRef(null)
  const csvInputRef = useRef(null)
  const [sniNumbers, setSniNumbers] = useState(null)
  const [url, setUrl] = useState('https://easy.dataprivacybox.com/')

  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    title: '',
    message: ''
  })
  

  const { startLoading, stopLoading, showError, showSnackbar } = props

  useEffect(() => {
    if(!!partner) {
      return
    }
    let pid
    let p
    const { partners } = props
    let id = props.computedMatch.params.id
    if(!id) {
      id = props.match.params.id
    }

    if(!!id) {
      pid = id
      p = partners[id]
    } else {
      pid = ''
      p = {}
    }

    if(!p) {
      return
    }

    setPartnerId(pid)
    setPartner(p)

    setName(safeValue(p, 'name'))
    setIntro(safeValue(p, 'intro'))
    setGroups(safeValue(p, 'groups', 'array'))
    setLocales(safeValue(p, 'locales', 'array'))
    let uPath = safeValue(p, 'users_path')
    setUsersPath(uPath)
    setUrl(`https://easy.dataprivacybox.com/${safeValue(p, 'url')}`)
    if(!!uPath) {
      setSignupRule('csv')
    } else {
      setSignupRule('open')
    }
    let logos = safeValue(p, 'logos', 'object')
    if(Object.keys(logos).length > 0) {
      setLogoMode('localized')
    } else {
      setLogoMode('universal')
    }

  }, [props.partners, props.match, props.computedMatch, props])

  useEffect(() => {
    let locs = sortedArrayFromObject(props.locales, 'language_code', true)
    setLocaleOptions([...locs])
  }, [props.locales])

  useEffect(() => {
    let grps = sortedArrayFromObject(props.documentGroups, 'name', true)
    setDocumentGroups([...grps])
  }, [props.documentGroups])

  const cancel = () => {
    const { history } = props
    history.replace('/parters')
  }

  const promptDelete = () => {
    setDeleteModal({
      isOpen: true,
      title: "Confirm deletion",
      message: `Are you sure you want to delete partner "${name}"?`
    })
  }

  const hideDeleteModal = () => {
    setDeleteModal({
      ...deleteModal,
      isOpen: false
    })
  }

  const confirmDelete = async () => {
    startLoading('Deleting')
    await deletePartner(partnerId)
    hideDeleteModal()
    showSnackbar({ text: 'Partner succesfully deleted', color: 'success' })
    const { history } = props
    history.replace('/partners')
    stopLoading()
  }

  const savePartner = async () => {
    startLoading('Saving changes')
    let p = partner
    p.name = name
    p.intro = intro
    p.groups = groups
    if(signupRule === 'csv') {
      p.users_path = usersPath
    } else {
      p.users_path = ''
    }
    p.locales = locales
    p.url = url.substring('https://easy.dataprivacybox.com/'.length)

    let result
    if(logoMode === 'universal') {
      result = await updatePartner(p, partnerId, logo)
    } else {
      result = await updatePartner(p, partnerId, logos, true)
    }

    if(!!result.error) {
      showError(result.error)
    }

    if(partner.sni_number_required && !!sniNumbers) {
      // update sni users list
      await updateSniUsers(sniNumbers.list)
    }

    if(!!result.error) {
      showError(result.error.message)
    } else if(!!result.id) {

      showSnackbar({ text: `Partner succesfully ${!partnerId ? 'created' : 'updated' }`, color: 'success' })
      const { history } = props
      history.replace(`/partner/${result.id}`)
    }
    stopLoading()
  }

  const uploadSniCsv = () => {
    csvInputRef.current.click()
  }

  const sniListFromCsv = (data) => {
    let rows = data.split('\n')
    let list = []
    for(let i in rows) {
      let components = rows[i].split(';')
      if(!isNaN(parseInt(components[0]))) {
        list.push({
          sni_number: components[0].replace(/[\n\r ]/g, ''),
          postcode: components[1].replace(/[\n\r ]/g, ''),
        })
      }
    }
    return list
  }

  const onCsvSubmitted = (e) => {
    let files = e.target.files
    if (files && files[0]) {
      var reader = new FileReader();

      reader.onload = (e) => {
        const components = files[0].name.split('.')
        const format = components[components.length - 1]
        const nameComponents = components
        nameComponents.splice(nameComponents.length - 1, 1)
        const name = nameComponents.join('.')
        const data = e.target.result.split(',')[1]
        let sniNumbersList = sniListFromCsv(Buffer.from(data, 'base64').toString('utf8'))
        setSniNumbers({
          list: sniNumbersList,
          name: name,
          format: format,
          dataType: files[0].type,
          data: data
        })
        csvInputRef.current.value = ""
      }
      reader.onerror = (err) => {
        console.log('reader on error', err)
      }
      reader.readAsDataURL(files[0]);
    } else {
      console.log('no files')
    }
  }

  const editLogo = (lang) => () => {
    setEditingLogo(lang)
    imageInputRef.current.click()
  }

  const onImageSubmitted = (e) => {
    let files = e.target.files
    if (files && files[0]) {
      var reader = new FileReader();

      reader.onload = (e) => {
        const components = files[0].name.split('.')
        const format = components[components.length - 1]
        if(!!editingLogo) {
          let ls = logos
          ls[editingLogo] = {
            data: e.target.result,
            format: format            
          }
          setLogos({...ls})
        } else {
          setLogo({
            data: e.target.result,
            format: format
          })
        }
        imageInputRef.current.value = ""
      }
      reader.onerror = (err) => {
        console.log('reader on error', err)
      }
      reader.readAsDataURL(files[0]);
    } else {
      console.log('no files')
    }
  }

  const handleGroupChange = (groupId) => (event) => {
    let grps = groups
    if(event.target.checked && grps.indexOf(groupId) === -1) {
      grps.push(groupId)
    } else if(!event.target.checked && grps.indexOf(groupId) !== -1) {
      grps.splice(grps.indexOf(groupId), 1)
    }
    setGroups([...grps])
  }

  const handleLocaleChange = (locId) => (event) => {
    let locs = locales
    if(event.target.checked && locs.indexOf(locId) === -1) {
      locs.push(locId)
    } else if(!event.target.checked && locs.indexOf(locId) !== -1) {
      locs.splice(locs.indexOf(locId), 1)
    }
    setLocales([...locs])
  }

  const handleUrlChange = (e) => {
    let base = 'https://easy.dataprivacybox.com/'
    let u
    let value = e.target.value
    if(value.substring(0, base.length) !== base) {
      u = url
    } else {
      u = value
    }
    setUrl(u)
  }

  const renderInfo = () => {
    return (
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <div className="stretch column">
            <h4>Partner Info</h4>
              <TextField
                id="name"
                label="Name"
                value={name}
                margin="normal"
                onChange={(t) => setName(t.target.value)}
              />
              <TextField
                id="intro"
                margin="normal"
                label="Intro"
                value={intro}
                onChange={(t) => setIntro(t.target.value)}
              />
              { partner?.sni_number_required ? (
                <FormControl margin="normal">
                  <FormLabel>SNI member numbers list</FormLabel>
                  { sniNumbers ? (
                    <div>
                      <span>{ `Click "Save changes" to update SNI users list with ${sniNumbers.list.length} entries` }</span>
                    </div>
                  ) : null }
                  <Button className="mt-2" variant="contained" color="primary" onClick={uploadSniCsv}>{ 'Upload SNI numbers list' }</Button>
                </FormControl>
              ) : null }
              {/* <FormControl margin="normal">
                <FormLabel>Client signup rules</FormLabel>
                <RadioGroup aria-label="signuprule" name="signuprule" value={signupRule} onChange={e => setSignupRule(e.target.value)}>
                  <FormControlLabel value="open" control={<Radio color="primary" />} label="Allow anyone to sign up" />
                  <FormControlLabel value="csv" control={<Radio color="primary" />} label="Allow only certain addresses" />
                </RadioGroup>
              </FormControl>
              <Collapse in={signupRule === 'csv'}>
                <div className="stretch column">
                  <TextField
                    id="userspath"
                    label="CSV file path on ftp server"
                    value={usersPath}
                    onChange={(t) => setUsersPath(t.target.value)}
                    helperText={'Path to a csv file containing list of permitted users. Path should start with "home/easydataei/dpbeasy/"'}
                  />       
                </div>         
              </Collapse> */}
              <TextField
                id="partnerurl"
                margin="normal"
                label="Partner url"
                disabled={!(!partner || !partner.url)}
                onChange={handleUrlChange}
                value={url}//  `https://easy.dataprivacybox.com/${!!partner ? partner.url : ''}`}
              />

              <InputLabel className="mt-2" id="locale-select-label">Partner locales</InputLabel>
              <FormGroup>
                { localeOptions.map((loc, li) => {
                  return (
                    <FormControlLabel
                      key={`locale_option_${li}`}
                      control={<Checkbox size="small" color="primary" checked={locales.indexOf(loc.id) !== -1} onChange={handleLocaleChange(loc.id)} name={`checked_${loc.id}`} />}
                      label={`${loc.language_long} (${loc.country_long})`}
                    />
                  )
                }) }
              </FormGroup>
              { renderLogo() }
          </div>
        </Grid>
        <Grid item sm={6}>
          <InputLabel id="locale-select-label">Document groups</InputLabel>
          <FormGroup>
            { documentGroups.map((group, gi) => {
              return (
                <FormControlLabel
                  key={`group_${gi}`}
                  control={<Checkbox size="small" color="primary" checked={groups.indexOf(group.id) !== -1} onChange={handleGroupChange(group.id)} name={`checked_${group.id}`} />}
                  label={`${group.name} (${group.locale})`}
                />
              )
            }) }
          </FormGroup>
        </Grid>
      </Grid>
    )
  }

  const getLanguages = () => {
    let langs = []
    for(let i in locales) {
      let lang = locales[i].split('_')[0].toLowerCase()
      if(langs.indexOf(lang) === -1) {
        langs.push(lang)
      }
    }
    return langs
  }

  const renderLogo = () => {
    return (
      <div className="stretch column">
        <InputLabel className="mt-2" id="locale-select-label">Partner logo</InputLabel>
        <FormControl>
          <RadioGroup aria-label="logomode" name="logomode" row value={logoMode} onChange={e => setLogoMode(e.target.value)}>
            <FormControlLabel value="universal" control={<Radio color="primary" />} label="Universal" />
            <FormControlLabel value="localized" control={<Radio color="primary" />} label="Localized" />
          </RadioGroup>
        </FormControl>
        { logoMode === 'universal' ? (
          <div className="stretch column">
            <img className="category-dialog-image" src={!!logo.data ? logo.data : !!partner && partner.logo ? partner.logo : '/assets/cover-placeholder.jpg' } />
            <div className="mt-3">
              <Button className="ml-2" variant="contained" color="primary" onClick={editLogo()}>{ !logo.data && (!partner || !partner.logo) ? 'Upload logo' : 'Update logo' }</Button>
            </div>
          </div>
        ) : getLanguages().map((lang, li) => {
          return (
            <div className="stretch column mb-3" key={`logo_wrapper_${li}`}>
              <InputLabel>{ `${lang} logo` }</InputLabel>
              <img className="category-dialog-image" src={!!logos[lang] && !!logos[lang].data ? logos[lang].data : !!partner && !!partner.logos && !!partner.logos[lang] ? partner.logos[lang] : '/assets/cover-placeholder.jpg' } />
              <div className="mt-3">
                <Button className="ml-2" variant="contained" color="primary" onClick={editLogo(lang)}>{ (!logos[lang] || !logos[lang].data) && (!partner || !partner.logos || !partner.logos[lang]) ? `Upload ${lang} logo` : `Update ${lang} logo` }</Button>
              </div>
            </div>
          )
        }) }        
        <input id="imageInput" ref={imageInputRef} hidden type='file' onChange={onImageSubmitted} accept="image/png, image/jpg" />
        <input id="imageInput" ref={csvInputRef} hidden type='file' onChange={onCsvSubmitted} accept="text/csv, image/jpg" />
      </div>
    )
  }

  const renderDeleteModal = () => {
    return (
      <Dialog open={deleteModal.isOpen}>
          <div className="p-4">
          <h4>{ deleteModal.title }</h4>
          <span>{ deleteModal.message }</span>
          <div className="mt-2">
            <Button className="mr-2" variant="contained" color="default" onClick={hideDeleteModal}>{ "Cancel" }</Button>            
            <Button variant="contained" color="secondary" onClick={confirmDelete}>Delete</Button>
          </div>
        </div>
      </Dialog>
    )
  }  

  return (
    <div className="stretch column content-page">
      { renderInfo() }
      <div className="floating-footer">
        <Button color="default" variant="contained" onClick={cancel}>
          Cancel
        </Button>
        { !!partnerId ? (
        <Button className="ml-2" color="secondary" variant="contained" onClick={promptDelete}>
          Delete
        </Button>
        ) : null }
        <Button className="ml-2" color="primary" variant="contained" onClick={savePartner}>
          Save changes
        </Button>
      </div>  
      { renderDeleteModal() }
    </div>
  )
}

const safeValue = (object, key, type = 'string') => {
  const keyComponents = key.split('.')
  let defaultValue
  if(type === 'string') {
    defaultValue = ''
  } else if(type === 'number') {
    defaultValue = 0
  } else if(type === 'array') {
    defaultValue = []
  } else if(type === 'object') {
    defaultValue = {}
  }
  let value = object
  for(let i in keyComponents) {
    if(!value) {
      return defaultValue
    }
    value = value[keyComponents[i]]    
  }
  if(!value) {
    return defaultValue
  }
  return value
}

export default PartnerDetail